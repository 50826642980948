import React from "react"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import EventList from "../../components/event-list"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const WorkshopSprache = () => {
  return (
    <Layout backdrop="workshops">
      <Seo
        title="Hölderlin in Einfacher und Leichter Sprache"
        description="Kann man auf Reisen gehen, ganz ohne in ein Flugzeug, in ein Auto oder in den Zug zu steigen? In drei Video-Tutorials für Kinder und Jugendliche von 10-14 Jahren zeigen wir, wie es geht und was das mit Hölderlin zu tun hat."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Hölderlin in Einfacher und Leichter Sprache",
              link: "/workshops/hoelderlin-in-einfacher-und-leichter-sprache",
            },
          ]}
        />
        <PageTitle>
          Workshops zu Hölderlin in Einfacher und Leichter Sprache
        </PageTitle>
        <Paragraph dropcap={true}>
          Wer war Friedrich Hölderlin? Warum wurde ein Turm am Neckar nach ihm
          benannt? Und warum sind seine Gedichte bis heute berühmt? Das möchten
          wir gerne mit allen Interessierten herausfinden: In einer Lesung mit
          Ausstellungsbesuch in Einfacher Sprache am 8. September und einem
          Schreibworkshop in Leichter Sprache am 15. September. Die Teilnahme
          ist kostenlos.
        </Paragraph>
        <EventList
          density="compact"
          showPastEvents={true}
          category="einfache-sprache"
        />
      </Stack>
    </Layout>
  )
}

export default WorkshopSprache
